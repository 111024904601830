<template>
  <CContainer>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
            <CRow class="justify-content-between">
              <CCol col="3"><strong>Precios de Sms</strong></CCol>
              <CCol col="3">
                <CButton color="success" v-on:click="modal_crear_precio_sms = true" >Crear Precios de Sms</CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
               <CContainer>
                  <table class="table">
                     <thead>
                       <tr>
                         <td><strong>Pais</strong></td>
                         <td><strong>Codigo Pais</strong></td>
                         <td><strong>Tipo Envio</strong></td>
                         <td><strong>Ruta Sms</strong></td>
                         <td><strong>Precio Cliente Sms</strong></td>
                         <td><strong>Accion</strong></td>
                       </tr>
                     </thead>  
                     <tbody>
                       <tr v-for="(item, key) in listaPrecios">
                         <td>{{item.pais}}</td>
                         <td>{{item.id_pais}}</td>
                         <td>{{item.tipo_envio}}</td>
                         <td>
                            <CSelect
                              :ref="key"
                              :options="listaRutas"
                              :value.sync="ruta_select = item.id_ruta"
                            />
                         </td>
                         <td>
                           <CInput  
                              :ref="key"
                              :value="item.precio_compra_usuario_final"
                            >
                            </CInput>
                         </td>
                         <td>
                           <CButton color="primary" v-on:click="actualizarPrecioSms($event,key,item.id_pais,item.tipo_envio)" v-if="$can('precio.precio_sms')">Guardar</CButton>
                           <CButton color="danger" v-on:click="abrirmodalEliminarPrecioSms(item.id_pais,item.tipo_envio)" v-if="$can('precio.precio_sms')">Eliminar</CButton>
                         </td>
                       </tr>
                     </tbody>
                  </table>
               </CContainer>
          </CCardBody>
        <!--<CCardFooter>
          
        </CCardFooter>-->
      </CCard>
    </CCol>
  </CRow>
         <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
        </div>
        <!-- /Modals  -->
        <div>
          <CModal
            :show.sync="modal_crear_precio_sms"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="lg"
            color="success"
          >
          <template #header>
              <h6 class="modal-title">Crear Precio Sms</h6>
              <CButtonClose @click="modal_crear_precio_sms = false" class="text-white"/>
            </template>
            <CRow>
              <CCol col="12">
                 <CSelect
                    label="Seleccionar Pais"
                    :ref=""
                    :options="listaPaises"
                    :value.sync="form_precio.id_pais"
                  />
              </CCol>
              <CCol col="12">
                 <CSelect
                    label="Seleccionar Ruta Envio"
                    :ref=""
                    :options="listaRutas"
                    :value.sync="form_precio.id_ruta"
                  />
              </CCol>
              <CCol col="12">
                 <CSelect
                    label="Tipo Envio"
                    :ref=""
                    :options="[{value:'1_via',label:'1 Via'},{value:'2_via',label:'2 Via'},{value:'flash',label:'Flash'}]"
                    :value.sync="form_precio.tipo_envio"
                  />
              </CCol>
              <CCol col="12">
                  <CInput  
                    label="Precio"
                    :ref=""
                    v-model="form_precio.precio"
                    onkeypress="return ( (event.charCode !=8 && event.charCode ==0) || event.keyCode ==46 || (event.charCode >= 48 && event.charCode <= 57))" 
                  >
                  </CInput>
              </CCol>
            </CRow>
            <template #footer>
              <CButton @click="modal_crear_precio_sms = false" color="danger">Cancelar</CButton>
              <CButton v-on:click.stop="crearPrecioSms" color="success">Aceptar</CButton>
            </template>
          </CModal>
          <CModal
            :show.sync="modal_eliminar_precio_sms"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="sm"
            color="success"
          >
          <template #header>
              <h6 class="modal-title">Eliminar Precio Sms</h6>
              <CButtonClose @click="modal_eliminar_precio_sms = false" class="text-white"/>
            </template>
            <CRow>
              <CCol col="12">
                  <p>Esta seguro de Eliminar Precio Sms</p>
              </CCol>
            </CRow>
            <template #footer>
              <CButton @click="modal_eliminar_precio_sms = false" color="danger">Cancelar</CButton>
              <CButton v-on:click.stop="eliminaPrecioSms" color="success">Aceptar</CButton>
            </template>
          </CModal>
        </div>
  </CContainer>
</template>

<script>
export default {
  name: 'PrecioCliente',
  data () {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Datos Guardado Correctamente.',
      user:{ 
        name:''
      },
      listaPrecios:[],
      listaRutas:[],
      listaPaises:[],
      ruta_select:null,
      modal_crear_precio_sms:false,
      modal_eliminar_precio_sms:false,
      form_precio:{
        id_pais:null,
        id_ruta:null,
        tipo_envio:'1_via',
        precio:'0.00',
      },
      form_elimina:{
        id_pais:null,
        tipo_envio:'',
      }
    }
  },
  methods: {
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/roles'})
    },
    obtenerPrecios () {
        const id = this.$route.params.id
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/precio/sms').then(response => {
            this.listaPrecios = response.data.listaPrecio;
            this.listaRutas = response.data.rutas_sms;
            this.listaPaises = response.data.paises_sms;
            this.form_precio.id_pais = this.listaPaises[0].value;
            this.form_precio.id_ruta = this.listaRutas[0].value;
        }).catch((e)=>{}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    validator (val) {
      return val ? val.length >= 4 : false
    },
    actualizarPrecioSms(event,key,id_pais,tipo_envio){
        //const id = this.$route.params.id
        let id_ruta = this.$refs[key][0].state;
        let precio = this.$refs[key][1].state;
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post('/sms/precio/sms',{
            id_pais:id_pais,
            id_ruta:id_ruta,
            precio:precio,
            tipo_envio:tipo_envio,
        })
        .then(response => {
          if (response.data == 'OK') {
            this.mensaje_success='Datos Guardado Correctamente.'
            this.mostrarNotificacion ++;
            this.obtenerPrecios();
          }else if(response.data == 'Error'){
              this.mensaje_warning = `Ya Se Encuentra Actualizado Los Datos del Pais ${id_pais}`;
              this.mostrarNotificacionAlerta += 1;
          }else{
            let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
          }
        }).catch((error)=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    crearPrecioSms(){
        if(this.form_precio.id_pais == null || this.form_precio.id_ruta == null || (this.form_precio.precio).trim() == '' || parseInt(this.form_precio.precio) == 0){
          this.mensaje_warning = `Completar los Campos Vacios`;
          this.mostrarNotificacionAlerta += 1;
          return false;
        }
        this.$store.dispatch('auth/loading_http','visible');
        this.form_precio.tipo = 'crear';
        this.$http.post('/sms/crear/precio/sms',this.form_precio)
        .then(response => {
          if (response.data == 'Creado'){
            this.mensaje_success='Datos Guardado Correctamente.'
            this.mostrarNotificacion ++;
            this.modal_crear_precio_sms = false
            this.obtenerPrecios();
          }else if(response.data == 'Existe'){
              this.mensaje_warning = `Ya Se Encuentra Creado Los Datos`;
              this.mostrarNotificacionAlerta += 1;
          }else if(response.data == 'Eliminado'){
              this.mensaje_success='Datos Eliminado Correctamente.'
              this.mostrarNotificacion += 1;
          }else{
            this.modal_crear_precio_sms = false
            let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
          }
        }).catch((error)=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
        this.modal_eliminar_precio_sms = false
    },
    eliminaPrecioSms(){
        this.modal_eliminar_precio_sms = false;
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post('/sms/crear/precio/sms',{
          tipo:'eliminar',
          id_pais:this.form_elimina.id_pais,
          tipo_envio:this.form_elimina.tipo_envio,
        })
        .then(response => {
          if(response.data == 'Eliminado'){
              this.mensaje_success='Datos Eliminado Correctamente.'
              this.mostrarNotificacion += 1;
              this.obtenerPrecios();
          }else{
            this.modal_crear_precio_sms = false
            let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
          }
        }).catch((error)=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    abrirmodalEliminarPrecioSms(id_pais,tipo_envio){
      this.modal_eliminar_precio_sms = true;
      this.form_elimina.id_pais = id_pais;
      this.form_elimina.tipo_envio = tipo_envio;
    }
  },
  mounted () {
      setTimeout(() => {
        this.obtenerPrecios();
      }, 100);
  },
}
</script>
